
import http from "@/common/http";
import { ClubApplyPass, ClubApplyReject, clubApplyStates, ClubApplyUnchecked } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { PaginationProps } from "ant-design-vue/es/pagination";

const columns = [
  // {
  //   title: "ID",
  //   width: 80,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "俱乐部ID",
    width: 80,
    dataIndex: "clubId",
    ellipsis: true,
    // fixed: "left",
  },

  {
    title: "俱乐部名称",
    width: 110,
    dataIndex: "clubName",
    ellipsis: true,
    // fixed: "left",
  },

  {
    title: "用户userId",
    width: 100,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "用户游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 140,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 70,
    dataIndex: "icon",
    ellipsis: true,
  },

  {
    title: "申请时间",
    width: 180,
    dataIndex: "applyTime",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 180,
    dataIndex: "remark",
    ellipsis: true,
  },

  {
    title: "审核时间",
    width: 180,
    dataIndex: "optionTime",
    ellipsis: true,
    // fixed: "right",
  },

  {
    title: "审核状态",
    width: 100,
    dataIndex: "optionType",
    ellipsis: true,
    fixed: "right",
  },

  // {
  //   title: "",
  //   key: "operation",
  //   align: "center",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 180,
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  components: {
    // Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        maskId: undefined,
        userId: undefined,
        clubId: undefined,
        optionType: undefined,
        startTime: undefined,
        endTime: undefined,
        nickName: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_join_apply", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = undefined;
      search.data.endTime = undefined;
      onSearch();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      columns,
      search,
      pagination,

      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      ClubApplyUnchecked,
      ClubApplyPass,
      ClubApplyReject,
      clubApplyStates,
    };
  },
});
